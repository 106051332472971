import React from 'react'
import SEO from "../../components/seo"
import AuthLayout from '../../components/Layout/AuthLayout'
import Login from '../../components/Layout/AuthLayout/Login'

const login = () => {
    return (
        <>
        <SEO title="Home" />
        <AuthLayout>
            <Login />
        </AuthLayout>
            
        </>
    )
}

export default login
