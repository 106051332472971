import React, { useState, useContext } from "react"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../../FormErrors"
import * as Yup from "yup"
import { FormControl, Box, TextField, Typography } from "@material-ui/core"
import Button from "../../../Button"
import clsx from "clsx"
import { useStyles } from "./style"
import { Link } from "gatsby"
import AuthContext from "../../../../context/auth/authContext"

const Login = () => {
  const classes = useStyles()
  const [showPassword, setShowPassword] = useState(false)
  const authContext = useContext(AuthContext)

  const { Login, loading } = authContext

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = e => {
    e.preventDefault()
  }


  const initialValues = {
    email: "",
    password: "",
  }

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    password: Yup.string().required("Password is empty"),
  })

  const onSubmit = values => {
    Login(values)
  }

  return (
    // <Container >
    <Box>
      <Typography align="center" className={classes.title}>
        Welcome Back
      </Typography>
      <Typography align="center" className={classes.subtitle}>
        Enter login details to proceed
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, getFieldProps }) => (
          <Form noValidate autoComplete="off" className={classes.form}>
            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Email Address</Typography>
              <TextField
                name="email"
                id="email"
                {...getFieldProps("email")}
                variant="outlined"
                size="small"
              />
              <ErrorMessage name="email" component={FormErrors} />
            </FormControl>

            <FormControl className={classes.formControl}>
              <Typography className={classes.label}>Password</Typography>
              <TextField
                name="password"
                id="password"
                {...getFieldProps("password")}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                size="small"
                InputProps={{
                  endAdornment: (
                    <Typography
                      position="end"
                      className={clsx(classes.actionText, classes.text)}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? "Hide" : "Show"}
                    </Typography>
                  ),
                }}
              />

              <ErrorMessage name="password" component={FormErrors} />
            </FormControl>
            <Link to="/user/forgot-password" style={{ textDecoration: "none" }}>
              <Typography
                align="right"
                className={clsx(classes.actionText, classes.text)}
              >
                Forgot password?
              </Typography>
              {/* <ReCAPTCHA
                sitekey={reCaptchaKey}
                onChange={() => setVerified(!verified)}
              /> */}
            </Link>
            <FormControl className={classes.submit}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
                loading={loading}
                // disabled={!verified}
              >
                Login
              </Button>
              
              <Box align="center">
                <Typography display="inline" className={classes.text}>
                  Don't have an account?
                </Typography>{" "}
                &nbsp;
                <Link to="/user/register" style={{ textDecoration: "none" }}>
                  <Typography
                    display="inline"
                    className={clsx(classes.actionText, classes.text)}
                  >
                    sign Up
                  </Typography>
                </Link>
              </Box>
            </FormControl>
          </Form>
        )}
      </Formik>
    </Box>
    // </Container>
  )
}

export default Login
